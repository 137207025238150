import { defineStore } from 'pinia';
import AdminService from '@/service/AdminService';
import PodcastService from '@/service/PodcastService';
import MediaService from '@/service/MediaService';
import { formatDateTime, formatDateTimeSQL } from '@/utils/ThmDateTimeUtils';

const podcastService = new PodcastService();
const mediaService = new MediaService();
const adminService = new AdminService();

export const usePodcastStore = defineStore('Podcasts', {
    state: () => ({
        loading: false,
        shows: [],
        show: {},
        episodes: [],
        episode: {},
    }),
    getters: {
        totalEpisodes() {
            return this.episodes.length && typeof this.episodes[0].totalRows != 'undefined' ? this.episodes[0].totalRows : 0;
        },
    },
    actions: {
        setEpisodeDefaults() {
            this.episode = { id: 0, season: 1, episode: 0, datePublished: formatDateTime(), isPublished: true };
        },
        async load(force = false) {
            if(!this.shows.length || force) {
                this.loading = true;
                await podcastService.getShows().then((shows) => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Shows', shows);
                    this.shows = shows;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        async setShow(showId) {
            /*
            // For some reason this results in a Froala error
            return this.load().then(() => {
                this.show = this.shows.find((obj) => obj.id == showId);
                return this.show;
            });
            */
            this.show = await podcastService.getShow(showId);
            return this.show;
        },
        async getEpisode(episodeId) {
            this.loading = true;
            return await podcastService
                .getEpisode(episodeId)
                .then((res) => {
                    //if(process.env.NODE_ENV !== 'production') console.log('📗 GET Episode', res);
                    return res;
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async setEpisode(episodeId) {
            return await this.getEpisode(episodeId).then((res) => {
                this.episode = res;
                if(this.episode.datePublished) this.episode.datePublished = formatDateTime(this.episode.datePublished); // Format for PrimeVue calendar component
                return res;
            });
        },
        async saveEpisode() {
            //console.log('saveEpisode ' + this.episode.id, this.episode);
            this.loading = true;
            let isNew = !this.episode.id || this.episode.id < 1;

            return await podcastService.getEpisode(this.episode.id).then((original) => {
                // Fetch original
                //let episode = { ...this.episode, datePublished: formatDateTimeISO( this.episode.datePublished ) }; // Format date for MS SQL
                let episode = { ...this.episode, datePublished: formatDateTimeSQL( this.episode.datePublished ) }; // Format date for MS SQL
                //console.log('SAVE:', this.episode);
                return podcastService.saveEpisode(episode).then((res) => {
                    // Save new
                    //if(process.env.NODE_ENV !== 'production') console.log('📙 POST Save podcast show', res);
                    adminService.log({ logTypeId: 6, referenceId: res.id, message: isNew ? 'Added' : 'Modified', content: JSON.stringify(res, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                    res.isNew = isNew;
                    //this.episodes.push(res);
                    if(isNew) this.episodes.unshift(res);
                    //this.episode = res;
                    return res;
                }).catch((error) => {
                    throw error;
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        async save() {
            this.loading = true;
            let isNew = !this.show.id || this.show.id < 1;

            return await podcastService.getShow(this.show.id).then((original) => {
                // Fetch original
                return podcastService
                    .saveShow(this.show)
                    .then((res) => {
                        // Save new
                        //if(process.env.NODE_ENV !== 'production') console.log('📙 POST Save podcast show', res);
                        adminService.log({ logTypeId: 5, referenceId: res.id, message: isNew ? 'Added' : 'Modified', content: JSON.stringify(res, null, 2), original: JSON.stringify(original, null, 2), language: 'json' });
                        res.isNew = isNew;
                        this.shows.push(res);
                        this.show = res;
                        return res;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        async getEpisodesPage(params) {
            this.loading = true;
            return await podcastService.getEpisodesPaged(params).then((res) => {
                this.episodes = res;
            }).catch((error) => {
                throw error;
            }).finally(() => {
                this.loading = false;
            });
        },
        async deleteEpisode() {
            return podcastService
                .deleteEpisode(this.episode.id)
                .then(() => {
                    // Log deletion
                    adminService.log({ logTypeId: 6, referenceId: this.episode.id, message: 'Delete', content: null, original: JSON.stringify(this.episode, null, 2), language: 'json' });

                    // Remove from episodes page, if present
                    this.episodes = this.episodes.filter((obj) => {
                        return obj.id != this.episode.id;
                    });

                    // Delete the audio file
                    if(this.episode.audioUrl) {
                        mediaService.deleteMediaObject('podcasts', this.episode.audioUrl).catch((error) => {
                            console.error(error);
                        });
                    }
                })
                .catch((error) => {
                    throw error;
                });
        }
    }
});
