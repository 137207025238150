<template>
    <ConfirmDialog />

    <form class="needs-validation" novalidate>
        <!-- Filter bar -->
        <div class="row mb-3">
            <div class="col">
                <CartoonsFilterBar />
            </div>
        </div>

        <!-- Cartoon list -->
        <div class="row mb-3">
            <div class="col">
                <section class="card card-border-color card-border-color-primary" id="cartoons">
                    <div class="row">
                        <div class="col">
                            <!--<Paginator :totalRecords="cartoonStore.totalRows" :rows="cartoonStore.cartoons.length" @page="onPage($event)" />-->
                            <PaginationThm v-model="route.query.pageIndex" :totalRecords="cartoonStore.totalRows" :pageSize="cartoonStore.cartoons?.length" @page="onPage" class="mt-2" />
                        </div>
                    </div>
                    <div class="card-body container-fluid">
                        <div class="row py-3" v-for="cartoon in cartoonStore.cartoons" :key="cartoon.cartoonId">
                            <div class="col-xs-12 col-sm-9 col-md-7">
                                <div class="mb-1"><span class="fw-500">ID:</span> {{ cartoon.cartoonId }}</div>
                                <div class="mb-1"><span class="fw-500">Cartoonist:</span> {{ getAuthorById(cartoon.authorId, 'fullName') }}</div>
                                <div class="mb-1"><span class="fw-500">Created Date:</span> {{ formatDateTime(cartoon.dateCreated) }}</div>
                                <div class="mb-3">
                                    <label :for="'DatePublished_' + cartoon.cartoonId" class="form-label fw-500">Published Date:</label>
                                    <div class="input-group">
                                        <Calendar
                                            :ref="'datePublished_' + cartoon.cartoonId"
                                            v-model="cartoon.datePublished"
                                            date-format="mm/dd/yy"
                                            :show-time="true"
                                            :show-seconds="false"
                                            hour-format="12"
                                            :show-icon="true"
                                            :manual-input="false"
                                            :id="'DatePublished_' + cartoon.cartoonId"
                                            @date-select="updateCartoon(cartoon)"
                                        />
                                    </div>
                                </div>

                                <div class="mb-1">
                                    <InputSwitch v-model="cartoon.isDefault" :id="'isDefault_' + cartoon.cartoonId" @change="updateCartoon(cartoon, true)" />
                                    <label class="switch-label" :for="'isDefault_' + cartoon.cartoonId">Default</label>
                                </div>

                                <div class="mb-1">
                                    <InputSwitch v-model="cartoon.isPublished" :id="'isPublished_' + cartoon.cartoonId" @change="updateCartoon(cartoon, true)" />
                                    <label class="switch-label" :for="'isPublished_' + cartoon.cartoonId">Published</label>
                                </div>

                                <div class="mb-2">
                                    <Button label="Delete" class="p-button-danger btn-delete" @click="deleteCartoon(cartoon)" />
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-3 col-md-5">
                                <Image :src="'https://media.townhall.com/Townhall/Car/b/' + cartoon.location" :alt="getAuthorById(cartoon.authorId, 'fullName')" class="preview-image" :preview="true" v-if="cartoon.location" />
                            </div>
                        </div>

                        <p class="fst-italic text-muted px-2 py-3" v-if="cartoonStore.cartoons != null && !cartoonStore.cartoons?.length">No cartoons found matching specified criteria.</p>
                    </div>
                    <div class="row">
                        <div class="col">
                            <!--<Paginator :totalRecords="cartoonStore.totalRows" :rows="cartoonStore.cartoons.length" @page="onPage($event)" />-->
                            <PaginationThm v-model="route.query.pageIndex" :totalRecords="cartoonStore.totalRows" :pageSize="cartoonStore.cartoons?.length" @page="onPage" class="mb-3" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { formatDateTime, formatDateTimeISO } from '@/utils/ThmDateTimeUtils';
import { useRoute, useRouter } from 'vue-router';
import { useCartoonStore } from '@/stores/CartoonStore';
import { useAuthorStore } from '@/stores/AuthorStore';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import CartoonsFilterBar from './FilterBar.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import PaginationThm from '@/components/PaginationThm.vue';

export default {
    name: 'Cartoons',
    components: {
        CartoonsFilterBar,
        ConfirmDialog,
        PaginationThm
    },
    setup() {
        const authorStore = useAuthorStore();
        const cartoonStore = useCartoonStore();
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const confirm = useConfirm();

        //const filterDefaults = { isPublished: false, isDefault: null };
        // 2022-08-18: Added SiteGroup to limit the authors list:
        const filterDefaults = { isPublished: false, siteGroup: 1, isDefault: null };
        const filter = ref({ ...filterDefaults });

        // Watch for route changes
        const unwatch = watch(
            //() => route.query, async params => {
            () => route.query, (query, fromQuery) => {
                //console.log('[watch] Cartoons');
                if(console.log.hide) console.log({query, fromQuery});
                filter.value = { ...filterDefaults, ...query };

                cartoonStore.getCartoons(filter.value).catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error Loading Cartoons 1', detail: err.message || err, life: 3000 });
                });
            }
        );

        // Mounted
        onMounted(() => {
            let query = Object.assign({}, route.query);
            filter.value = { ...filterDefaults, ...query };

            cartoonStore.getCartoons(filter.value).catch((err) => {
                toast.add({ severity: 'error', summary: 'Error Loading Cartoons 2', detail: err.message || err, life: 3000 });
            });
        });

        // Unwatch route.query
        onBeforeUnmount(() => {
            unwatch();
        });

        function getAuthorById(id, fld = null) {
            var author = authorStore.authors?.find(({ authorId }) => authorId === id);
            if(fld) return author && typeof author[fld] != 'undefined' ? author[fld] : null;
            return author;
        }

        function updateCartoon(cartoon, refresh = false) {
            var cartoonData = Object.assign({}, cartoon); // Clone so that it's non-reactive, else the date changes when reformatted
            cartoonData.datePublished = formatDateTimeISO(cartoonData.datePublished);
            cartoonStore.cartoon = cartoonData;

            cartoonStore.save().then(() => {
                //if(console.log.hide) console.log({refresh});
                if(refresh) cartoonStore.getCartoons(filter.value);
            }).catch((err) => {
                toast.add({ severity: 'error', summary: 'Update Failed', detail: err.message || err, life: 3000 });
            });
        }

        function deleteCartoon(cartoon) {
            confirm.require({
                message: 'Are you sure you want to delete this cartoon?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    cartoonStore.cartoon = cartoon;
                    cartoonStore.delete().then(() => {
                        // Reload current cartoon page
                        cartoonStore.getCartoons(filter.value);
                    }).catch((err) => {
                        toast.add({ severity: 'error', summary: 'Delete Failed', detail: err.message || err, life: 3000 });
                    });
                }
            });
        }

        function onPage(e) {
            router.push({ query: { ...filter.value, pageIndex: parseInt(e.page) } });
            cartoonStore.getCartoons({ ...filter.value, pageIndex: e.page });
        }

        return {
            cartoonStore,
            deleteCartoon,
            formatDateTime,
            getAuthorById,
            onPage,
            route,
            updateCartoon
        };
    },
};
</script>

<style lang="scss" scoped>
#cartoons {
    /*
    .cartoon {
        width: 100%;
        max-width: 350px;
        cursor: pointer;
    }
    */
    button {
        margin-bottom: 0.4em;
    }
    .btn-edit {
        margin-right: 0.4em;
    }
    .fw-500 {
        font-weight: 500;
    }
    div.row {
        &:nth-child(even) {
            background-color: rgb(243, 243, 243);
        }
    }
}
</style>
<style>
#cartoons .p-image-preview-container img {
    width: 100%;
    max-width: 350px;
    cursor: pointer;
}
</style>
